import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/styles.scss'
import store from './store'
import VueMobileDetection from "vue-mobile-detection";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import Vue3TouchEvents from "vue3-touch-events";
import VueScrollTo from 'vue-scrollto';
import VueSocialChat from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'

const vuetify = createVuetify({
    components,
    directives,
})


createApp(App)
    .use(VueScrollTo, {
        duration: 100,
        easing: "ease",
        force: true,
        cancelable: true,
})
    .use(VueSocialChat)
    .use(store)
    .use(router)
    .use(VueMobileDetection)
    .use(vuetify)
    .use(Vue3TouchEvents)
    .mount('#app')
