// import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue';
//
// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/brochure',
//     name: 'brochure',
//     children: [],
//     component: () => import(/* webpackChunkName: "flipbook" */ '@/modules/daybook/components/FlipbookVue.vue')
//   },
// ]
// for (let i = 1; i <= 20; i++) {
//   routes[1].children.push({
//     path: `/${i}`,
//     name: `${i}`,
//     component: () => import(/* webpackChunkName: "flipbook" */ '@/modules/daybook/components/FlipbookVue.vue')
//   });
// }
//
// routes.push({
//   path: '/:catchAll(.*)',
//   redirect: '/',
// });
//
// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// })
//
// export default router;


import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/brochure',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'brochure',
    children: [],
    component: () => import(/* webpackChunkName: "flipbook" */ '@/modules/daybook/components/FlipbookVue.vue')
  },
]
for (let i = 1; i <= 20; i++) {
  routes[1].children.push({
    path: `/${i}`,
    name: `${i}`,
    component: () => import(/* webpackChunkName: "flipbook" */ '@/modules/daybook/components/FlipbookVue.vue')
  });
}

routes.push({
  path: '/:catchAll(.*)',
  redirect: '/',
});

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;




