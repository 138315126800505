<template>
  <div class="home">
    <body id="page-top">
    <!-- Mobile Navigation-->
        <MobileStyle :landscape="isLandscape"  v-if="$isMobile()"></MobileStyle>
      <div v-else>
        <v-parallax
        >
        <header class="custom-parallax">
          <div class="parrallax-content">
                <nav v-if="!$isMobile() " class="navbar navbar-expand-lg customNabvar" id="mainNav">
                  <div class="container">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                      Menu
                      <i class="fas fa-bars ms-1"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarResponsive">
                      <ul class="navbar-nav text-uppercase  navbar-nav-border" style="align-items: center">
                        <li class="nav-item"><a class="nav-link" v-scroll-to="'#page-top'">Home</a></li>
                        <li class="nav-item"><a class="nav-link" v-scroll-to="'#disenos'">Diseños</a></li>
                        <li class="nav-item logo-item">
                          <div class="d-flex flex-column fill-height align-center text-white img-logo ">
                            <img class="greenhills img-logo" src="./assets/img/logo-w.png" alt="GREEN HILLS LOGO" />
                          </div>
                        </li>
                        <li class="nav-item"><a class="nav-link" v-scroll-to="'#services'">Brochure</a></li>
                        <li class="nav-item"><a class="nav-link" v-scroll-to="'#portfolio'">Instagram</a></li>
                      </ul>
                    </div>
                      </div>
                </nav>
<!--            <a class="navbar-brand" href="#page-top"><img src="./assets/img/logo.png" alt="TVS LOGO" /></a>-->
<!--            <div class="parrallax">-->
<!--            </div>-->
          </div>
        </header>
        </v-parallax>
      </div>
   <gallery-houses id="disenos" class="bg-blue-dark"></gallery-houses>
    <GoogleMaps></GoogleMaps>
    <!-- Services-->
    <section class="pt-15 pb-15" id="services">
        <div :class="!$isMobile() ? 'text-center pb-5' : 'text-center pb-0; z-index:10 position-relative'">
        <h2 class="section-heading pb-5 text-uppercase">BROCHURE</h2>
      </div>
      <div :class="!$isMobile() ? 'containerflipbook' : 'noflipbook'">
        <div class="row text-center">
          <iframe class="iframeflipbook" :src='iframeSrc' frameborder="0"></iframe>
        </div>
      </div>
    </section>
    <!-- Portfolio Grid-->
    <section class="page-section-portafolio pb-10" id="portfolio" >
      <div v-if="$isMobile()">
        <div class="text-center pb-2 pt-16">
          <h2 class="section-heading text-uppercase">INSTAGRAM</h2>
        </div>
      </div>
      <div v-else>
        <div class="text-center pb-5">
          <h2 class="section-heading text-uppercase">INSTAGRAM</h2>
        </div>
      </div>
      <div>
        <div v-if="$isMobile()" class="container">
        <EntryList :galleryData="gallery"/>
        </div>
        <div v-else class="container">
        <EntryList :galleryData="gallery"/>
        </div>
      </div>
    </section>
    <!-- Team-->
    <section class="page-section-equipo bg-beige" id="team">
      <div class="container">
        <div class="text-center pb-5">
          <h2 class="section-heading text-uppercase">NUESTRO EQUIPO</h2>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="./assets/img/team/miguel.jpg" alt="..." />
              <h4>MIGUEL DORIA</h4>
              <p class="text-muted">Arquitecto Y Diseñador</p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="./assets/img/team/ronald.jpg" alt="..." />
              <h4>RONALD LOGMANS</h4>
              <p class="text-muted">CEO</p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="./assets/img/team/vanessa.jpg" alt="..." />
              <h4 style="font-size:1.4rem">VANESSA CASTAÑEDA</h4>
              <p class="text-muted">Comercial Financiera</p>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="team-member">
              <img class="mx-auto rounded-circle" src="./assets/img/team/carlos.jpg" alt="..." />
              <h4>CARLOS VANEGAS</h4>
              <p class="text-muted">Constructor</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
    </body>
  </div>
  <SocialChat></SocialChat>
</template>

<script>
import {defineAsyncComponent} from "vue";
import '@/views/css/styles.css';
import router from "@/router";

export default {
    components :{
    GalleryHouses: defineAsyncComponent(() => import('@/modules/daybook/components/GalleryHouses.vue')),
    SocialChat: defineAsyncComponent(() => import('@/modules/daybook/components/WhatsAppComponent.vue')),
    MobileStyle: defineAsyncComponent(() => import('@/modules/daybook/components/MobileStyle.vue')),
    GoogleMaps: defineAsyncComponent(() => import('@/modules/daybook/components/GoogleMaps.vue')),
    EntryList: defineAsyncComponent(() => import('@/modules/daybook/components/CarouselGallery.vue')),
    Footer: defineAsyncComponent(() => import('@/modules/daybook/components/FooterComponent.vue')),
    },

  data: function () {
    return {
      gallery: {},
      statusBrochure: false,
      iframeSrc: '',
      isLandscape: false
    }
  },

  computed: {
    whatsappLink() {
      const phoneNumber = "+573042909906";
      const message = encodeURIComponent("Hola! Quisiera más información sobre Green Hills");
      return `https://wa.me/${phoneNumber}?text=${message}`;
    }
  },
  methods: {
    checkOrientationMain() {
      this.isLandscape = window.orientation === 90 || window.orientation === -90;
    },
  },

  created() {
    this.iframeSrc = window.location.origin+'/#/brochure';
    const token = process.env.VUE_APP_SECRET_KEY
    const url = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,thumbnail_url,caption,permalink,timestamp&access_token=${token}`;
    fetch(url)
        .then(response => response.json())
        .then(data => {
          this.gallery = data
        });
    document.addEventListener('keydown', function (event) {
      if (event.keyCode === 116) {
        router.push('/');
        event.preventDefault();
      }
    });
  },
  mounted() {
    window.addEventListener('orientationchange', this.checkOrientationMain);
    this.checkOrientationMain();
  }
}
</script>

<style lang="scss">

.navbar-collapse{
  place-content: center;
}

.navbar-nav .nav-link {
  font-family: 'Kiona';
}
.googleMaps{
  height: 35rem;
}

.navbar-nav-border {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar-nav-border .nav-item {
  position: relative;
}

.navbar-nav-border .nav-link {
  padding: 0.5rem 1rem;
  color: #ffffff; /* Set the text color */
  text-decoration: none;
  border-bottom: solid 1px #ffffff; /* Set the border */
  cursor: pointer;
}

.navbar-nav-border .logo-item .nav-link {
  padding: 0; /* No padding for logo link */
}

.navbar-nav-border .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px; /* Height of the white line */
  background-color: #ffffff; /* White line color */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out;
}

.navbar-nav-border .nav-link:hover::after {
  opacity: 0;
}

.customNabvar{
  top: 0%;
  position: absolute !important;
}

.parrallax-content{
  background-image: url('./assets/img/10-2.jpg');
}
.parrallax-content{
  position: relative;
  overflow: hidden;
  height: 100vh;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.parrallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.parrallax:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: -1;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 120% auto;
}

.custom-parallax {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.img-logo{
  max-width: 32rem !important;
}
//#mainNav{
//  background-color: #04116d;
//}

#mainNav .navbar-toggler{
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-weight: normal;
  margin-left: -13%;
  margin-right: -10%;
}

.mobileMenu{
  position: absolute;
  width: calc(40vw);
  top: calc(55vh);
  left: calc(30vw);
  border-color: rgba(0,0,0,0) !important;
  font-family: Kiona !important;
  color: #FFF !important;
  z-index: 12;
}

#services{
  width: 95%;
  max-width: 98% !important;
  padding-left: 1rem;
}

.containerflipbook{
  height: 40rem;
  width: 104%;
}

.iframeflipbook{
 height: 40rem;
 width: 100%;
}
.noflipbook{
  max-height: 30rem;
  margin-top: -3rem;
}
.greenhills{
  max-width: 100%;
}
.page-section-portafolio {
  padding: 0rem 0;
}
.page-section-equipo {
  padding: 2rem 0;
}
@media (max-width: 600px) {
  .page-section-portafolio {
    padding: 1.5rem 0 !important;
  }
}
</style>



